<template>
  <el-dialog
    :visible="show"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    title="Add Verification Code"
    width="450px"
    @close="cancel"
  >
    <h4 v-if="number">
      Add a verification code from {{ number.number }}
    </h4>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="180px"
    >
      <el-form-item
        label="Enter Craigslist Code"
        prop="code"
      >
        <el-input v-model="formData.code" />
      </el-form-item>

      <el-form-item
        label="Re-enter Craigslist Code"
        prop="code_confirm"
      >
        <el-input v-model="formData.code_confirm" />
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">
        Cancel
      </el-button>
      <el-button type="primary" @click="submit">
        Submit
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'AddVerificationCodeDialog',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    number: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      formData: {
        code: '',
        code_confirm: ''
      },
      rules: {
        code: [
          { required: true, message: 'This field is required', trigger: 'blur' }
        ],
        code_confirm: [
          { required: true, message: 'This field is required', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value !== this.formData.code) {
                return callback(new Error('Codes do not match'))
              }
              return callback()
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    /**
     * onClick handler for submit button.
     * Emits 'submit' event if form data is valid.
     */
    submit () {
      this.$refs['form'].validate(valid => {
        if (!valid) {
          return
        }
        const data = {
          ...this.number,
          code: this.formData.code
        }
        this.$emit('submit', data)
        this.reset()
      })
    },
    /**
     * onClick handler for cancel button.
     * Resets form fields and emits 'cancel' event.
     */
    cancel () {
      this.$emit('close')
      this.reset()
    },
    /**
     * Reset form to default values.
     */
    reset () {
      this.$refs['form'].resetFields()
      this.$refs['form'].clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  .el-dialog__body {
    padding-top: 0;

    h4 {
      margin-bottom: 2em;
    }
  }
}
</style>
