<template>
  <div v-loading="loading" class="phone-verification">
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
      class="header"
    >
      <div>
        <el-input
          v-model="search"
          placeholder="Search"
          clearable
        />
      </div>
      <div>
        <el-button @click="fetchNumbers">
          Refresh
        </el-button>
      </div>
    </el-row>

    <el-row>
      <h3>Pending Verification</h3>
    </el-row>

    <el-row>
      <el-table
        :data="filteredNumbers"
      >
        <el-table-column
          label="Phone Number"
          prop="number"
          sortable
        />
        <el-table-column
          label="Last Used"
          prop="last_succeeded"
          sortable
        >
          <template slot-scope="scope">
            {{ scope.row.last_succeeded && format(parseISO(scope.row.last_succeeded), 'yyyy-MM-dd h:mm a') }}
          </template>
        </el-table-column>
        <el-table-column
          label="Verification Method"
          prop="preferred_method"
          :formatter="formatPreferredMethod"
          sortable
        />
        <el-table-column
          label="Notes"
          prop="notes"
          min-width="200"
          class-name="notes"
        />
        <el-table-column type="expand">
          <template slot-scope="props">
            <h4>Notes</h4>
            <p>{{ props.row.notes }}</p>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="medium"
              @click="addVerificationBtnHandler(scope.row)"
            >
              Add Verification Code
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <add-code-dialog
      :show="showAddCodeDialog"
      :number="selectedNumber"
      @close="showAddCodeDialog = false"
      @submit="addVerificationCode"
    />
  </div>
</template>

<script>
import CraigslistAPI from '@/services/api/craigslist'
import AddVerificationCodeDialog from './_components/AddVerificationCodeDialog'
import { format, parseISO } from 'date-fns'

export default {
  name: 'PhoneVerification',
  components: {
    'add-code-dialog': AddVerificationCodeDialog
  },
  data () {
    return {
      loading: false,
      search: '',
      numbers: [],
      selectedNumber: null,
      showAddCodeDialog: false
    }
  },
  computed: {
    filteredNumbers () {
      const search = this.search.toLowerCase()
      return this.numbers.filter(number =>
        number.number.includes(search) ||
        number.notes.toLowerCase().includes(search)
      )
    }
  },
  created () {
    this.format = format
    this.parseISO = parseISO
    this.fetchNumbers()
  },
  methods: {
    /**
     * Fetch phone verification numbers from the API.
     */
    async fetchNumbers () {
      try {
        this.loading = true
        this.numbers = await CraigslistAPI.verification_numbers.list({ pending: true })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Formats the preferred method values
     */
    formatPreferredMethod (row, column, cellValue) {
      switch (cellValue) {
        case 'sms':
          return 'SMS'
        case 'voice':
          return 'Voice'
        default:
          return cellValue
      }
    },
    /**
     * onClick handler for Add Verification button
     *
     * @param {Object} number - phone verification number
     */
    addVerificationBtnHandler (number) {
      this.selectedNumber = number
      this.showAddCodeDialog = true
    },
    /**
     * Submits a verification code to the API
     *
     * @param {Object} formData
     */
    async addVerificationCode (formData) {
      try {
        this.loading = true
        await CraigslistAPI.verification_numbers_code.create(formData)
        this.showAddCodeDialog = false
        this.$message({
          message: 'Verification code submitted!',
          type: 'success',
          duration: 3500
        })
        this.fetchNumbers()
      } catch (err) {
        this.loading = false
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.phone-verification {
  .header {
    margin: 1em 0;
  }
  h3 {
    margin: 0.5em 0;
  }
  ::v-deep .notes .cell {
    white-space: nowrap;
  }
  .el-icon-more {
    transform: rotate(90deg);
    padding: 5px;

    &:hover {
      cursor: pointer;
    }
  }
  ::v-deep .capitalize {
    text-transform: capitalize;
  }
}
</style>
